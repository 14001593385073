<template>
	<div class="home">
		<div class="shop-search">
			<img src="@public/img/vigneron-online/home/background.jpg" />
			<div class="container clearfix">
				
				<div class="vertical-middle">

					<div class="heading-block center nobottomborder">
						<h1>{{ $t('shop.shop.search.title') }}</h1>
					</div>
					<div class="landing-wide-form clearfix">
						<div v-if="redirectionLoading" class="redirection spinner">
							<div class="bounce1"></div>
							<div class="bounce2"></div>
							<div class="bounce3"></div>
						</div>
						<div v-if="suggestionsLoading" class="suggestions spinner">
							<div class="bounce1"></div>
							<div class="bounce2"></div>
							<div class="bounce3"></div>
						</div>
						<div class="col_full mb-0">
							<span class="subtitle">{{ $t('shop.shop.search.sub-title') }}</span>
							<vue-autosuggest
								v-model="search"
								:suggestions="shopSuggestions"
								class=""
								:get-suggestion-value="getShopSuggestionValue"
								@selected="onShopSuggestionSelected"
								@input="onShopInputChange($event)"
								:input-props="{id: 'search-shop', placeholder:$t('shop.shop.search.form.input_placeholder'), class:'sm-form-control form-control not-dark autosuggest__input', autocomplete:'off'}"
							>
								<template slot-scope="{suggestion}">
									<div class="shop-suggestion">
										<div class="logo">
											<img :src="'/img/vigneron-online/' + suggestion.item.path + '/logo.jpg'" :alt="suggestion.item.name">
										</div>
										<div class="info">
											<span class="name">{{suggestion.item.name}}</span>
											<span class="address">{{getAddressFromCompany(suggestion.item.company)}}</span>
										</div>
										
									</div>
								</template>
							</vue-autosuggest>
							<div class="example">(Ex: Vins Faller)</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="container clearfix">
			<div class="line"></div>
		</div>
		<div class="container clearfix">

					<div class="col_three_fifth bottommargin">
						<img data-animate="fadeInLeftBig" src="@public/img/vigneron-online/home/imac.png" alt="Imac">
					</div>

					<div class="col_two_fifth bottommargin-lg col_last">

						<div class="heading-block">
							<h2>{{ $t('home.teaser.title') }}</h2>
							<span>{{ $t('home.teaser.sub-title') }}</span>
						</div>

						<p>{{ $t('home.teaser.text') }}</p>

						<a href="javascript:void(0)" @click="showContactModal" class="button button-border button-rounded button-large noleftmargin topmargin-sm">{{ $t('home.teaser.button') }}</a>

					</div>

				

				</div>
	</div>
</template>

<style>

.home .line {
	margin:30px 0px;
}

.shop-search {
	position:relative;
	overflow: hidden;
	background-image: linear-gradient(-180deg,rgba(0,0,0,.3),rgba(0,0,0,.1));
	min-height: 450px;
}

.shop-search > img {
	position:absolute;
	z-index: -1;
	max-width: none;
}

.shop-search h1 {
	font-size:50px;
	font-weight:900;
	color: #ffffff;
	text-shadow: 0 2px 5px rgba(0,0,0,.5)
	/* text-shadow: #FFF 2px 2px, #FFF -2px -2px, #FFF -2px 2px, #FFF 2px -2px; */
}

.shop-search .subtitle {
	display:block;
	text-align: center;
	margin-bottom: 5px;
	font-size: 20px
}


.shop-search .example {
	margin-left:18px;
	margin-top:5px;
}

.suggestions.spinner {
	right: 20px;
    width: 100px;
    padding-left: 2px;
}

.redirection.spinner {
	width: 100%;
	left: 0px;
	background: white;
	opacity: 50%;
}

.suggestions.spinner > div {
	width: 14px;
	height: 14px;	
	margin-top:18px;
}

.redirection.spinner > div {
	margin-top:16px;
}

.shop-search .container {
	margin-bottom: 50px;
	margin-top:50px;
}

.shop-search .landing-wide-form {
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	position:relative;
	padding:15px;
	
}

.shop-search .form-control {
	height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
}

.shop-suggestion {
	display:flex;
}

.shop-suggestion .logo {
	width:60px;
	height:60px;
	margin-right: 10px;
}

.shop-suggestion .info {
	display:flex;
	flex-direction: column;
	padding:10px;
}

.shop-suggestion .info > span {
	display:block;
	line-height: 20px;
}

.shop-suggestion .info .name {
	font-weight: bold;
}

.shop-search .autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 5px;
}

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { routerTypes, languagesTypes } from '@fwk-client/store/types';
import { types as layoutTypes } from '../../store/layout';

@Component({
  components: {}
})
export default class Home extends mixins(GenericPage) {

	shopSuggestions:any = [];

	search = "";
	redirectionLoading = false;
	suggestionsLoading = false;

	getShopSuggestionValue(suggestion:any) {
    	return suggestion.item.name;
	}

	onShopSuggestionSelected(suggestion:any) {
		var path = suggestion.item.path;
		
		// We display confirmation page
		var languageCode = this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
		// We display loading animation
		this.redirectionLoading = true;  
      	// We redirect to the confirmation page
      	this.$router.push('/'+languageCode+'/'+path).catch(err => {});
	}

	onShopInputChange(text:string) {
		// We need to get the list of addresses based on what has been entered by the end user
		if(text.length < 4) {
			this.shopSuggestions = [];  
			return;
		}

		this.suggestionsLoading = true;
		this.$shop.shop.search(this.search).then((response:any) => {
			this.suggestionsLoading = false;
			if(response.suggestions) {  
				this.shopSuggestions = [{data:response.suggestions}]; 
			}
			else {
				this.shopSuggestions = [];
			}
		});
	}

	getAddressFromCompany(company:any) {
		var address = company.address;
		var city = address.city[0].toUpperCase() +  address.city.slice(1).toLowerCase(); 
		var str = address.addressLines.join(", ") + ", " + address.postalCode + " " + city;
		return str;
	}

	showContactModal() {
        this.$store.commit('layout/'+ layoutTypes.mutations.SHOW_CONTACT_FORM_SWITCH);
    }

}
</script>